import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

// any CSS you import will output into a single css file.
import "./assets/styles/about.css";
import "./assets/styles/accordion.css";
import "./assets/styles/app.css";
import "./assets/styles/colors.css";
import "./assets/styles/contacts.css";
import "./assets/styles/contact-button.css";
import "./assets/styles/customButton.css";
import "./assets/styles/customSelect.css";
import "./assets/styles/discounted.css";
import "./assets/styles/divider.css";
import "./assets/styles/faqs.css";
import "./assets/styles/fonts.css";
import "./assets/styles/footer.css";
import "./assets/styles/form.css";
import "./assets/styles/headline.css";
import "./assets/styles/locations.css";
import "./assets/styles/navigationBar.css";
import "./assets/styles/plans.css";
import "./assets/styles/promoPlan.css";
import "./assets/styles/techInfo.css";
import "./assets/styles/viber.css";
import "./assets/styles/whyWoofy.css";

import "@fortawesome/fontawesome-free/css/all.css";

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
